import { useSelector, useDispatch } from 'react-redux'
import { setUser, initialState } from 'store/auth/userSlice'
import { onSignInSuccess, onSignOutSuccess } from 'store/auth/sessionSlice'
import appConfig from 'configs/app.config'
import { REDIRECT_URL_KEY } from 'constants/app.constant'
import { useNavigate } from 'react-router-dom'
import useQuery from './useQuery'
import { signInUserData } from 'auth/authData'

function useAuth() {
    const dispatch = useDispatch()

    const navigate = useNavigate()

    const query = useQuery()

    const { token, signedIn } = useSelector((state) => state.auth.session)

    const signIn = async (values) => {
        const { userName, password } = values;
        const user = signInUserData.find(
            (user) => user.accountUserName === userName && user.password === password
        );
    
        if (user) {
            const { avatar, userName, email, authority } = user;
            const token = 'wVYrxaeNa9OxdnULvde1Au5m5w63';
    
            dispatch(onSignInSuccess(token));
            dispatch(
                setUser({
                    avatar,
                    userName,
                    email,
                    authority,
                })
            );
    
            const redirectUrl = query.get(REDIRECT_URL_KEY);
            navigate(
                redirectUrl ? redirectUrl : appConfig.authenticatedEntryPath
            );
    
            return {
                status: 'success',
                message: '',
            };
        } else {
            return {
                status: 'failed',
                message: 'Invalid credentials',
            };
        }
    };

    const signOut = () => {
        dispatch(onSignOutSuccess());
        dispatch(setUser(initialState));
        navigate(appConfig.unAuthenticatedEntryPath);
    };

    return {
        authenticated: token && signedIn,
        signIn,
        signOut,
    }
}

export default useAuth
