export const signInUserData = [
    {
        id: '21',
        avatar: '/img/avatars/thumb-1.jpg',
        userName: 'Admin',
        email: 'email@example.com',
        authority: ['admin', 'user'],
        password: 'iW#&U40S04kz',
        accountUserName: 'admin',
    },
    {
        id: '22',
        avatar: '/img/avatars/thumb-2.jpg',
        userName: 'Team',
        email: 'email@example.com',
        authority: ['user'],
        password: 'JG8hizx6O6',
        accountUserName: 'team',
    },
    {
        id: '23',
        avatar: '/img/avatars/thumb-2.jpg',
        userName: 'Manager',
        email: 'email@example.com',
        authority: ['manager'],
        password: '@b%86IK34#l1',
        accountUserName: 'manager',
    },
    {
        id: '24',
        avatar: '/img/avatars/thumb-2.jpg',
        userName: 'Leads',
        email: 'email@example.com',
        authority: ['leads'],
        password: '#l3%8@bIK461',
        accountUserName: 'leads',
    }
]
